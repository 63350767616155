<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <form class="form" v-if="form != null">
        <div class="form-row">
          <div class="col-md-6 form__item">
            <div class="form-group">
              <label class="form__label">Maximum Exposure to a customer</label>
              <select
                class="w-100 select--lg"
                v-model="form.exposureType"
                v-bind:class="{ 'input-required': err.exposureType }"
              >
                <option disabled>Select maximum exposure</option>
                <option value="Unlimited">Unlimited</option>
                <option value="SumOfAllLoans">Sum Of All Loans</option>
                <option value="SumOfAllLoansMinusDepositBalance">
                  Sum Of All Loans Minus Deposits Balance
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-md-6"
            v-if="
              form.exposureType == 'SumOfAllLoans' ||
              form.exposureType === 'SumOfAllLoansMinusDepositBalance'
            "
          >
            <div class="form__item">
              <label class="form__label">Maximum Amount</label>
              <input
                class="input form__input w-100"
                type="number"
                min="0"
                v-model="form.exposureAmount"
                v-bind:class="{ 'input-required': err.exposureAmount }"
              />
            </div>
          </div>
          <div class="col-md-6 form__item">
            <div class="form-group">
              <label class="form__label"
                >Customers can recieve mulitple loans</label
              >
              <select class="select--lg w-100" v-model="form.multipleLoans">
                <option disabled>Select option</option>
                <option value="Yes">
                  Yes, Unlimited number of active loans
                </option>
                <option value="No">
                  No, Only one active loan per customer
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >Customer may be in more than one group</label
              >
              <select
                class="w-100 select--lg"
                v-model="form.multipleGroupMemberships"
              >
                <option disabled>Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >Customer and Group Required Assignments
              </label>
              <div class="d-flex mt-3">
                <label
                  for="branch"
                  class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                  >branch
                  <input
                    type="checkbox"
                    id="branch"
                    class="form-"
                    v-model="form.clientRequireBranch"
                  />
                  <span class="checkmark"></span>
                </label>
                <label
                  for="center"
                  class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                  >center
                  <input
                    type="checkbox"
                    id="center"
                    class="form-"
                    v-model="form.clientRequireCentre"
                  />
                  <span class="checkmark"></span>
                </label>
                <label
                  for="account"
                  class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                  >account officer
                  <input
                    type="checkbox"
                    id="account"
                    class="form-"
                    v-model="form.clientRequireAccountOfficer"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Available Dashboard Sections</label>
              <div class="d-flex">
                <div class="row mt-3">
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Clients
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.availableDashboardSectionsClients"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Current Till
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.availableDashboardSectionsCurrentTill"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Favourite Views
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.availableDashboardSectionsFavouriteViews"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Indicators
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.availableDashboardSectionsIndicators"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Latest Activity
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.availableDashboardSectionsLatestActivity"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <!-- <label
                    class="
                      has-checkbox
                      mr-4
                      form__checkbox
                      text--dark text--capital
                    "
                    >Tasks
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.availableDashboardSectionsTasks"
                    />
                    <span class="checkmark"></span>
                  </label> -->
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Upcoming Repayments
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="
                        form.availableDashboardSectionsUpcomingRepayments
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >Prevent Duplicate on Customer Parameter</label
              >
              <div class="d-flex mt-3">
                <div class="row">
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Document ID and Type
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.customerDuplicateOptionDocumentIdAndType"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Email
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.customerDuplicateOptionEmail"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Firstname and Lastname
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="
                        form.customerDuplicateOptionFirstNamesAndLastName
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                  <br />
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Home phone
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.customerDuplicateOptionHomePhone"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Lastname and Date of Birth
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.customerDuplicateOptionLastNameAndBirthDate"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="has-checkbox mr-4 form__checkbox text--dark text--capital spartial"
                    >Mobile Phone
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.customerDuplicateOptionMobilePhone"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >Enable Two-Factor Authentication (2FA)</label
              >
              <div class="d-flex mt-1">
                <div class="row">
                  <label
                    class="has-checkbox ml-3 form__checkbox text--dark text--capital spartial"
                    >Enable 2FA
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.enable2Fa"
                      @change="toggle2FA()"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <!-- <div class="mt-3" v-if="form.enable2Fa">
                <label class="form__label">
                  Where would you want the user to be sent 2FA verification token?
                </label>
                <div class="d-flex mt-1">
                  <div class="row">
                    <label
                      class="
                        has-checkbox
                        ml-3
                        form__checkbox
                        text--dark text--capital
                        spartial
                      "
                      >Send to email
                      <input
                        type="checkbox"
                        class="form-"
                        v-model="form.enable2FaThroughEmail"
                        @change="toggleSendMedium('email')"
                      />
                      <span class="checkmark"></span>
                    </label>

                    <label
                      class="
                        has-checkbox
                        ml-3
                        form__checkbox
                        text--dark text--capital
                        spartial
                      "
                      >Send to phone
                      <input
                        type="checkbox"
                        class="form-"
                        v-model="form.enable2FaThroughPhone"
                        @change="toggleSendMedium('phone')"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div> -->

              <div class="mt-3" v-if="form.enable2Fa">
                <label class="form__label">
                  How often can a user verify 2FA?
                </label>
                <div class="d-flex mt-1">
                  <div class="row">
                    <label
                      class="has-checkbox ml-3 form__checkbox text--dark text--capital spartial"
                      >At every login
                      <input
                        type="checkbox"
                        class="form-"
                        v-model="form.enable2FaEveryLogin"
                        @change="toggle2FAPeriod('every')"
                      />
                      <span class="checkmark"></span>
                    </label>

                    <label
                      class="has-checkbox ml-3 form__checkbox text--dark text--capital spartial"
                      >Daily (24hrs)
                      <input
                        type="checkbox"
                        class="form-"
                        v-model="form.enable2FaDaily"
                        @change="toggle2FAPeriod('daily')"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 form__item">
            <div class="form-group">
              <label class="form__label">Duplicate customer checks </label>
              <select class="select--lg w-100">
                <option disabled>Select option</option>
                <option value="None">None</option>
                <option value="Warning">Warning</option>
                <option value="Error">Error</option>
              </select>
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >Minimum Days In Arrears Before Write-Off</label
              >
              <input
                v-model="form.arrearsDaysBeforeWriteOff"
                v-bind:class="{
                  'input-required': err.arrearsDaysBeforeWriteOff,
                }"
                type="number"
                class="input form__input form__input--lg w-100"
                placeholder="Enter Minimum Days"
                min="0"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >Enter Maximum Allowed ID Documents Attachments</label
              >
              <input
                v-model="form.maxAllowedIdDocumentAttachments"
                v-bind:class="{
                  'input-required': err.maxAllowedIdDocumentAttachments,
                }"
                type="number"
                min="0"
                class="input form__input form__input--lg w-100"
                placeholder="Enter Maximum Allowed ID Documents Attachments"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >Enter Maximum Allowed Journal Entry Documents
                Attachments</label
              >
              <input
                v-model="form.maxAllowedJournalEntryDocumentAttachments"
                v-bind:class="{
                  'input-required':
                    err.maxAllowedJournalEntryDocumentAttachments,
                }"
                type="number"
                min="0"
                class="input form__input form__input--lg w-100"
                placeholder="Enter Maximum Allowed Journal Entry Documents Attachments"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >Maximum Days Before Undo Close Loans</label
              >
              <input
                type="number"
                min="0"
                v-model="form.maxAllowedUndoClosurePeriod"
                v-bind:class="{
                  'input-required': err.maxAllowedUndoClosurePeriod,
                }"
                class="input form__input form__input--lg w-100"
                placeholder="Enter Maximum Days"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Minimum Group Size Limit</label>
              <input
                type="number"
                min="0"
                v-model="form.minGroupSizeLimit"
                v-bind:class="{
                  'input-required': err.minGroupSizeLimit,
                }"
                class="input form__input form__input--lg w-100"
                placeholder="Enter minimum group size"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Maximum Group Size Limit</label>
              <input
                type="number"
                min="0"
                v-model="form.maxGroupSizeLimit"
                v-bind:class="{
                  'input-required': err.maxGroupSizeLimit,
                }"
                class="input form__input form__input--lg w-100"
                placeholder="Enter maximum group size"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Group Size Limit Type</label>
              <select
                class="w-100 select--lg"
                v-model="form.groupSizeLimitType"
                v-bind:class="{ 'input-required': err.groupSizeLimitType }"
              >
                <option disabled>Select option</option>
                <option value="None">None</option>
                <option value="Hard">Hard</option>
                <option value="Warning">Warning</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">New Customer Initial State</label>
              <select
                class="w-100 select--lg"
                v-model="form.defaultClientState"
                v-bind:class="{ 'input-required': err.defaultClientState }"
              >
                <option disabled>Select option</option>
                <option value="PendingApproval">Pending Approval</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label"
                >New Credit Arrangement Initial State</label
              >
              <select
                class="w-100 select--lg"
                v-model="form.defaultLineOfCreditState"
                v-bind:class="{
                  'input-required': err.defaultLineOfCreditState,
                }"
              >
                <option disabled>Select option</option>
                <option value="PendingApproval">Pending Approval</option>
                <option value="Approved">Approved</option>
              </select>
            </div>
          </div>
          <div class="form__item">
            <div>
              <span class="text--regular text--grey text--600 text--capital"
                >Two-Man Rules</span
              >
              <div class="mt-2 form__item form__item--label">
                <label
                  for="users"
                  class="has-checkbox form__checkbox text--dark text--capital"
                  >Required separate users for approvals and disbursals
                  <input
                    type="checkbox"
                    id="users"
                    v-model="form.approvalDisbursalTwoManRuleEnabled"
                    v-bind:class="{
                      'input-required': err.approvalDisbursalTwoManRuleEnabled,
                    }"
                    class="form-"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <button
          class="button form__button form__button--lg"
          type="submit"
          v-if="!isSaving && userCanCreateinternalControls"
          @click="saveForm"
        >
          Save Changes
        </button>
        <!-- loader button -->
        <a
          role="button"
          class="button form__button form__button--lg w-25 save-changes"
          v-if="isSaving"
          :disabled="true"
        >
          <div class="spinner-border text-light" role="status"></div>
        </a>
        <button
          class="button form__action__text form__action__text--danger ml-5"
          :disabled="isSaving"
          @click="getInternalControls()"
        >
          Cancel
        </button>
      </form>
    </div>
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";
import internalControlsService from "@/core/services/general-setup-service/internal-controls.service";
export default Vue.extend({
  name: "internal-controls",
  data: function () {
    return {
      content_header: {
        title: "Internal Controls",
        subTitle: "",
      },
      enums: {
        defaultClientStates: ["PendingApproval", "Approved"],
      },
      isLoading: false,
      isSaving: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      internalControls: null,
      form: null,
      // form: {
      // arrearsDaysBeforeWriteOff: 0,
      //   defaultClientState: null,
      //   defaultLineOfCreditState: null,
      //   // duplicateClientContraintAction: "",
      //   exposureAmount: 0,
      //   exposureType: null,
      //   groupSizeLimitType: null,
      //   maxAllowedJournalEntryDocumentAttachments: 0,
      //   maxAllowedUndoClosurePeriod: 0,
      //   maxGroupSizeLimit: 0,
      //   minGroupSizeLimit: 0,
      //   approvalDisbursalTwoManRuleEnabled: true,
      //   maxAllowedIdDocumentAttachments: 0,
      //   multipleLoans: null,
      //   multipleGroupMemberships: null
      // },
      err: {
        arrearsDaysBeforeWriteOff: false,
        defaultClientState: false,
        defaultLineOfCreditState: false,
        duplicateClientContraintAction: false,
        exposureAmount: false,
        exposureType: false,
        groupSizeLimitType: false,
        maxAllowedJournalEntryDocumentAttachments: false,
        maxAllowedUndoClosurePeriod: false,
        maxGroupSizeLimit: false,
        minGroupSizeLimit: false,
        maxAllowedIdDocumentAttachments: false,
        multipleLoans: false,
      },
    };
  },
  methods: {
    clearZeros(num) {
      if (parseInt(num) <= 0) {
        return "";
      }

      return num;
    },
    toggle2FA() {
      if (!this.form.enable2Fa) {
        this.form.enable2FaThroughEmail = false;
        this.form.enable2FaThroughPhone = false;
        this.form.enable2FaDaily = false;
        this.form.enable2FaEveryLogin = false;
      }
    },
    toggleSendMedium(medium) {
      if (medium === "email") {
        this.form.enable2FaThroughPhone = false;
      }

      if (medium === "phone") {
        this.form.enable2FaThroughEmail = false;
      }
    },
    toggle2FAPeriod(period) {
      if (period === "every") {
        this.form.enable2FaDaily = false;
      }

      if (period === "daily") {
        this.form.enable2FaEveryLogin = false;
      }
    },
    async getInternalControls() {
      try {
        this.isLoading = true;
        const res = await internalControlsService.get();
        if (res.data.data != null) {
          this.internalControls = res.data.data;
          this.form = this.internalControls;
          this.form.clientRequireAccountOfficer =
            this.form.assignmentConstraints.accountOfficer;
          this.form.clientRequireBranch =
            this.form.assignmentConstraints.branch;
          this.form.clientRequireCentre =
            this.form.assignmentConstraints.centre;

          this.form.availableDashboardSectionsClients =
            this.form.availableDashboardSections.clients;
          this.form.availableDashboardSectionsCurrentTill =
            this.form.availableDashboardSections.currentTill;
          this.form.availableDashboardSectionsFavouriteViews =
            this.form.availableDashboardSections.favouriteViews;
          this.form.availableDashboardSectionsIndicators =
            this.form.availableDashboardSections.indicators;
          this.form.availableDashboardSectionsLatestActivity =
            this.form.availableDashboardSections.latestActivity;
          this.form.availableDashboardSectionsTasks =
            this.form.availableDashboardSections.tasks;
          this.form.availableDashboardSectionsUpcomingRepayments =
            this.form.availableDashboardSections.upcomingRepayments;

          this.form.customerDuplicateOptionDocumentIdAndType =
            this.form.customerDuplicateOptions.documentIdAndType;
          this.form.customerDuplicateOptionEmail =
            this.form.customerDuplicateOptions.email;
          this.form.customerDuplicateOptionFirstNamesAndLastName =
            this.form.customerDuplicateOptions.firstNamesAndLastName;
          this.form.customerDuplicateOptionHomePhone =
            this.form.customerDuplicateOptions.homePhone;
          this.form.customerDuplicateOptionLastNameAndBirthDate =
            this.form.customerDuplicateOptions.lastNameAndBirthDate;
          this.form.customerDuplicateOptionMobilePhone =
            this.form.customerDuplicateOptions.mobilePhone;

          switch (this.form.defaultClientState) {
            case "Pending approval":
              this.form.defaultClientState = "PendingApproval";
              break;
            default:
              break;
          }
          switch (this.form.defaultLineOfCreditState) {
            case "Pending approval":
              this.form.defaultLineOfCreditState = "PendingApproval";
              break;
            default:
              break;
          }
          switch (this.form.exposureType) {
            case "Sum of all loans minus deposit balance":
              this.form.exposureType = "SumOfAllLoansMinusDepositBalance";
              break;
            case "Sum of all loans":
              this.form.exposureType = "SumOfAllLoans";
              break;
            default:
              break;
          }
          this.form.arrearsDaysBeforeWriteOff =
            parseInt(this.internalControls.arrearsDaysBeforeWriteOff) > 0
              ? this.internalControls.arrearsDaysBeforeWriteOff
              : "";
          this.form.maxAllowedIdDocumentAttachments =
            parseInt(this.internalControls.maxAllowedIdDocumentAttachments) > 0
              ? this.internalControls.maxAllowedIdDocumentAttachments
              : "";
          this.form.maxAllowedJournalEntryDocumentAttachments =
            parseInt(
              this.internalControls.maxAllowedJournalEntryDocumentAttachments
            ) > 0
              ? this.internalControls.maxAllowedJournalEntryDocumentAttachments
              : "";
          this.form.maxAllowedUndoClosurePeriod =
            parseInt(this.internalControls.maxAllowedUndoClosurePeriod) > 0
              ? this.internalControls.maxAllowedUndoClosurePeriod
              : "";
          this.form.minGroupSizeLimit =
            parseInt(this.internalControls.minGroupSizeLimit) > 0
              ? this.internalControls.minGroupSizeLimit
              : "";
          this.form.maxGroupSizeLimit =
            parseInt(this.internalControls.maxGroupSizeLimit) > 0
              ? this.internalControls.maxGroupSizeLimit
              : "";
          // this.form.defaultClientState = this.internalControls.defaultClientState;
          // this.form.defaultLineOfCreditState = this.internalControls.defaultLineOfCreditState;
          // this.form.duplicateClientContraintAction = this.internalControls.duplicateClientContraintAction;
          // this.form.exposureAmount = this.internalControls.exposureAmount;
          // this.form.exposureType = this.internalControls.exposureType;
          // this.form.groupSizeLimitType = this.internalControls.groupSizeLimitType;
          // this.form.approvalDisbursalTwoManRuleEnabled = this.internalControls.approvalDisbursalTwoManRuleEnabled;
          // this.form.multipleLoans = this.internalControls.multipleLoans;
          // this.form.multipleGroupMemberships = this.internalControls.multipleGroupMemberships;
          // this.form.assignmentConstraints = this.internalControls.assignmentConstraints;
          // multipleGroupMemberships
        }
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await internalControlsService.update(this.form);
          this.getInternalControls();
          this.alertSuccess("Internal controls updated");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    validateForm() {
      this.clearValidation();
      if (
        this.form.exposureType &&
        // this.form.arrearsDaysBeforeWriteOff
        // this.form.maxAllowedUndoClosurePeriod
        this.form.groupSizeLimitType &&
        this.form.defaultClientState &&
        this.form.defaultLineOfCreditState
        // this.form.duplicateClientContraintAction &&
        // this.form.exposureAmount &&
        // this.form.maxAllowedJournalEntryDocumentAttachments &&
        // this.form.maxGroupSizeLimit &&
        // this.form.minGroupSizeLimit &&
        // this.form.maxAllowedIdDocumentAttachments &&
        // this.form.multipleLoans
      ) {
        return true;
      }

      if (this.form.arrearsDaysBeforeWriteOff < 0) {
        this.err.arrearsDaysBeforeWriteOff = true;
      }
      if (!this.form.defaultClientState) {
        this.err.defaultClientState = true;
      }
      if (!this.form.defaultLineOfCreditState) {
        this.err.defaultLineOfCreditState = true;
      }
      if (!this.form.duplicateClientContraintAction) {
        this.err.duplicateClientContraintAction = true;
      }
      if (this.form.exposureAmount < 0) {
        this.err.exposureAmount = true;
      }
      if (!this.form.exposureType) {
        this.err.exposureType = true;
      }
      if (!this.form.groupSizeLimitType) {
        this.err.groupSizeLimitType = true;
      }
      if (this.form.maxAllowedJournalEntryDocumentAttachments < 0) {
        this.err.maxAllowedJournalEntryDocumentAttachments = true;
      }
      if (this.form.maxAllowedUndoClosurePeriod < 0) {
        this.err.maxAllowedUndoClosurePeriod = true;
      }
      if (this.form.maxGroupSizeLimit < 0) {
        this.err.maxGroupSizeLimit = true;
      }
      if (this.form.minGroupSizeLimit < 0) {
        this.err.minGroupSizeLimit = true;
      }
      if (this.form.maxAllowedIdDocumentAttachments < 0) {
        this.err.maxAllowedIdDocumentAttachments = true;
      }
      if (!this.form.multipleLoans) {
        this.err.multipleLoans = true;
      }

      return false;
    },
    clearValidation() {
      this.err = {
        arrearsDaysBeforeWriteOff: false,
        defaultClientState: false,
        defaultLineOfCreditState: false,
        duplicateClientContraintAction: false,
        exposureAmount: false,
        exposureType: false,
        groupSizeLimitType: false,
        maxAllowedJournalEntryDocumentAttachments: false,
        maxAllowedUndoClosurePeriod: false,
        maxGroupSizeLimit: false,
        minGroupSizeLimit: false,
        maxAllowedIdDocumentAttachments: false,
        multipleLoans: false,
      };
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getInternalControls();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateinternalControls() {
      const internalControls = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = internalControls;
      return permissionsList.CanCreateInternalControl;
    },
    userCanEditinternalControls() {
      const internalControls = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = internalControls;
      return permissionsList.CanEditInternalControl;
    },
    userCanDeleteinternalControls() {
      const internalControls = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = internalControls;
      return permissionsList.CanDeleteInternalControl;
    },
  },
});
</script>
<style lang="scss" scoped>
.spartial {
  width: 170px !important;
  margin-bottom: 15px !important;
}
</style>
